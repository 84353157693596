/* eslint-disable */
import request from '@/utils/request'

/**
 * 函数描述：登录系统
 * @param {Object} lander 表单对象
 */
export function login(lander) {
  return request({
    url: '/sys/login',
    method: 'post',
    data: lander
  })
}

/**
 * 函数描述：获取登录的验证码
 */
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

/**
 * 函数描述：从XX途径登录系统
 * @param {Object} data 表单对象
 */
export function loginFromXD(data) {
  const params = {
    username: data.username,
  }
  return request({
    url: '/loginFromXD',
    method: 'post',
    params: params
  })
}
/**
 * 函数描述：从XX途径登录系统
 * @param {Object} data 表单对象
 */
export function sendRegPhone(params) {
  return request({
    url: '/sys/sendRegPhone',
    method: 'post',
    params: params
  })
}

/**
 * 函数描述：CA锁绑定网联平台账号，获取验证码
 * @param {Object} params 表单对象
 */
 export function bindPhone(params) {
  return request({
    url: '/huiyuanCadog/bindPhone',
    method: 'post',
    params: params
  })
}

/**
 * 函数描述：获取用户信息
 */
export function getInfo() {
  return request({
    url: '/sys/getInfoByRole',
    method: 'get'
  })
}

/**
 * 函数描述：获取路由信息
 */
export function getRouters() {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}

/**
 * 函数描述：获取用户配置文件
 */
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

/**
 * 函数描述：用户头像上传
 * @param {Object} data 头像文件
 */
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

/**
 * 函数描述：用户密码重置
 * @param {String} oldPassword 旧密码
 * @param {String} newPassword 新密码
 */
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

/**
 * 函数描述：修改用户个人信息
 * @param {Object} data 表单对象
 */
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

/**
 * 函数描述：用户注册
 * @param {Object} data 注册信息
 */
export function register(data) {
  return request({
    url: '/sys/register',
    method: 'post',
    data: data
  })
}
/**
 * 函数描述：忘记密码-账号验证
 * @param {Object} data 电话号、短信code、
 */
export function checkUsername(data) {
  return request({
    url: '/sys/checkUsername',
    method: 'post',
    data: data
  })
}
/**
 * 函数描述：重置密码
 * @param {Object} data 新密码
 */
export function updatepassword(data) {
  return request({
    url: '/sys/updatepassword',
    method: 'post',
    data: data
  })
}

/**
 * 函数描述：修改密码的验证码
 * @param {Object} data 表单对象
 */
 export function xgRegPhone(params) {
  return request({
    url: '/sys/xgRegPhone',
    method: 'post',
    params: params
  })
}
/**
 * 函数描述：忘记密码的验证码
 * @param {Object} data 表单对象
 */
 export function returnRegPhone(params) {
   console.log(123)
  return request({
    url: '/sys/returnRegPhone',
    method: 'post',
    params: params
  })
}

/**
 * 函数描述：CA绑定账号
 * @param {Object} data 注册信息
 */
 export function bindCaByPhone(data) {
  return request({
    url: '/huiyuanCadog/bindCaByPhone',
    method: 'post',
    data: data
  })
}