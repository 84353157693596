/* eslint-disable */
import { login, getInfo, loginFromXD } from '@/api/system/user'
import { getToken, setToken, removeToken,setTokenByZf ,getTokenByZf,removeZfToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  zfToken:getTokenByZf(),
  isLoading:false,
  perfectFlag:false,
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  userId: '',
  personUserGuid:"",
  permissions: [],
  userInfo: {},
  sfCzgly:'',
  //企业审核状态-正式库[null:没有企业; 0:编辑中; 1:待审核; 2:审核通过; 3:审核不通过]
  qyshzt: '',
  //企业审核状态-草稿库[0:编辑中; 1:待审核; 2:审核通过; 3:审核不通过]
  qyCgShzt:'',
  //平台
  platFormList:[],
  //平台
  platFormListLyt:[],
  //证书服务商
  serviceProviders:[],
  //介质厂商
  mediumList:[],
  //CA锁类型
  caDogType:[],
  //签章服务商
  manufacturer:[],
  // 证书类型(企业证书、个人证书)
  certificateType: [],
  //代替session的info，存储登录信息
  getterInfo: {},
  //角色列表-对象格式
  roleList: {
    1:'企业',
    2:'项目负责人或授权委托人',
    3:'专家(或评标人评委)',
    4:'造价师',
    5:'管理员',
    6:'自然人',
    8:'临时专家',
    9:'监管人员'
  },
  //角色列表-数组格式
  roleOptionList: [],
  //阉割版本状态码
  castrationFlag: false,
  //是否显示前往旧平台
  toTheOldPlatform: true,
  //是否显示辽易通的登录和增值服务
  showLytContent: true,
}

const mutations = {
  SET_QYSHZT: (state, qyshzt) => {
    state.qyshzt = qyshzt
  },
  GET_QYSHZT: (state) => {
    return state.qyshzt;
  },
  SET_QYCGSHZT: (state, qyCgShzt) => {
    state.qyCgShzt = qyCgShzt
  },
  GET_QYCGSHZT: (state) => {
    return state.qyCgShzt;
  },
  SET_CADOGTYPE: (state, caDogType) => {
    state.caDogType = caDogType
  },
  GET_CADOGTYPE: (state) => {
    return state.caDogType;
  },
  SET_SFCZGLY: (state, sfCzgly) => {
    state.sfCzgly = sfCzgly
  },
  GET_SFCZGLY: (state) => {
    return state.sfCzgly;
  },
  SET_ROLELIST: (state, roleOptionList) => {
    state.roleOptionList = roleOptionList
  },
  GET_ROLELIST: (state) => {
    return state.roleOptionList;
  },
  SET_GETTERINFO: (state, getterInfo) => {
    state.getterInfo = getterInfo
  },
  GET_GETTERINFO: (state) => {
    return state.getterInfo;
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_TOKENZF: (state, zfToken) => {
    state.zfToken = zfToken
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_PLATFORMLIST: (state, platFormList) => {
    state.platFormList = platFormList
  },
  GET_PLATFORMLIST: (state) => {
    return state.platFormList;
  },
  SET_PLATFORMLISTLYT: (state, platFormListLyt) => {
    state.platFormListLyt = platFormListLyt
  },
  GET_PLATFORMLISTLYT: (state) => {
    return state.platFormListLyt;
  },
  SET_SERVICEPROVIDERS: (state, serviceProviders) => {
    state.serviceProviders = serviceProviders
  },
  GET_SERVICEPROVIDERS: (state) => {
    return state.serviceProviders;
  },
  SET_MEDIUMLIST: (state, mediumList) => {
    state.mediumList = mediumList
  },
  GET_MEDIUMLIST: (state) => {
    return state.mediumList;
  },
  SET_MANUFACTURER: (state, manufacturer) => {
    state.manufacturer = manufacturer
  },
  GET_MANUFACTURER: (state) => {
    return state.manufacturer;
  },
  SET_CERTIFICATETYPE: (state, certificateType) => {
    state.certificateType = certificateType
  },
  GET_CERTIFICATETYPE: (state) => {
    return state.certificateType;
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
   // console.log('43',userInfo)
    const { username, password, code, uuid } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, code: code, uuid: uuid }).then(response => {
        if (response.code === 200) {
          const { token } = response.data
          commit('SET_TOKEN', token)
          setToken(token);
          resolve();
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  //通过token登录
  loginByToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', token)
      setToken(token);
      resolve();
    })
  },
  loginByZf({ commit }, zfToken) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKENZF', zfToken)
      setTokenByZf(zfToken);
      resolve();
    })
  },
  // user loginFromXD
  loginFromXD({ commit }, data) {
    const { username } = data
    return new Promise((resolve, reject) => {
      loginFromXD({ username: username }).then(response => {
        if (response.code === 200) {
          const { token } = response
          commit('SET_TOKEN', token)
          setToken(token);
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        // console.log('131:',response)
        if (!response) {
          reject('验证失败，请重新登录。')
        }
        //const { roles, user, permissions } = response;
        const data = {
          userInfo: response.data.tInfoGeren
        }
        // console.log(data.userInfo)
        if(response.data.roleId){
          data.userInfo.roleId = response.data.roleId;
          data.userInfo.roleName = state.roleList[response.data.roleId]
          data.userInfo.yhdh = response.data.tInfoGeren.yhdh;
        }
        
        //console.log('139:',data)
        commit('SET_USERINFO', response.data.tInfoGeren)
        commit('SET_SFCZGLY', response.data.sfCzgly)
        commit('SET_QYSHZT', response.data.qyShzt)
        commit('SET_QYCGSHZT', response.data.qyCgShzt)
        // commit('SET_QYSHZT', '1')
        // commit('SET_SFCZGLY', null)
        // localStorage.setItem("name", response.data.yhXm)
        // const data = {
        //   roles: roles,
        //   name: user.userName,
        //   avatar: user.avatar,
        //   introduction: user.remark
        // }
        // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!')
        // }
        // commit('SET_ROLES', roles)
        // commit('SET_NAME', user.userName)
        // commit('SET_AVATAR', user.avatar)
        // commit('SET_INTRODUCTION', user.remark)
        // commit('SET_PERMISSIONS', permissions)
        resolve(data)
      }).catch(error => {
        reject(error)
      })

    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      removeToken()
      resetRouter()
      localStorage.removeItem('token')
      resolve()
    })
  },

  zflogout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKENZF', '')
      removeZfToken()
      sessionStorage.removeItem('zfToken')
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
