import Complaint from "@/components/Complaint";
import BackToTop from "@/components/BackToTop"
import UploadImgCardNew from "@/components/UploadImgCardNew";
import Descriptions from "@/components/Descriptions";
import UploadImgNewCard from "@/components/UploadImgNewCard";
import Pagination from "@/components/Pagination/index";
import CerModuleDialog from "@/components/CerModuleDialog"
import Qianzhang from "@/components/QianZhang"
import LicenseQzDialog from '@/components/LicenseQzDialog'
export default {
  install(Vue) {
    Vue.component('Complaint',Complaint)
    Vue.component('BackToTop',BackToTop)
    Vue.component('UploadImgCardNew',UploadImgCardNew)
    Vue.component('Descriptions',Descriptions)
    Vue.component('UploadImgNewCard',UploadImgNewCard)
    Vue.component('Pagination',Pagination)
    Vue.component('CerModuleDialog',CerModuleDialog)
    Vue.component('Qianzhang',Qianzhang)
    Vue.component('LicenseQzDialog',LicenseQzDialog)
  }
}