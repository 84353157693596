import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import { Message } from 'element-ui'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login'),
        name: 'login',
        meta: {title: '登录', affix: true}
    },
    {
        path: '/register',
        component: () => import('@/views/register'),
        name: 'register',
        meta: {title: '注册', affix: true}
    },
    {
        path: '/forgetPassword',
        component: () => import('@/views/forgetPassword'),
        name: 'forgetPassword',
        meta: {title: '忘记密码', affix: true}
    },
    {
        path: '/',
        component: Layout,
        // redirect: '/dashboard',
        children: [
            {
                path: '/',
                component: () => import('@/views/business/home'),
                name: 'dashboard',
                meta: {title: '主页', icon: 'dashboard', affix: true}
            },
            {
                path: '/proclamation',
                component: () => import('@/views/business/proclamation'),
                name: 'proclamation',
                meta: {title: '信息中心', affix: true}
            },
            {
                path: '/proclamationDetial',
                component: () => import('@/views/business/proclamation/detail.vue'),
                name: 'proclamationDetial',
                meta: {title: '信息中心详情', affix: true}
            },
            {
                path: '/product',
                component: () => import('@/views/business/product'),
                name: 'product',
                meta: {title: '产品中心', affix: true}
            },
            {
                path: '/productDetail',
                component: () => import('@/views/business/product/detail.vue'),
                name: 'productDetail',
                meta: {title: '产品详情', affix: true, requireAuth: true}
            },
            {
                path: '/purchase',
                component: () => import('@/views/business/product/purchase.vue'),
                name: 'purchase',
                meta: {title: '购买详情', affix: true, requireAuth: true}
            },
            {
                path: '/services',
                component: () => import('@/views/business/services'),
                name: 'services',
                meta: {title: '材价服务', affix: true}
            },
            {
                path: '/about',
                component: () => import('@/views/business/about'),
                name: 'about',
                meta: {title: '关于我们', affix: true}
            },
            {
                path: '/detail',
                component: () => import('@/views/detail'),
                name: 'Detail',
                hidden: true
            },
            {
                path: '/cs',
                component: () => import('@/views/csForm'),
                name: 'CS',
                hidden: true
            },
            {
                path: '/rgxjdetail',
                component: () => import('@/views/business/services/rgxjdetail.vue'),
                name: 'rgxjdetail',
                meta: {title: '人工询价', affix: true}
            },
            {
                path: '/scjdetail',
                component: () => import('@/views/business/services/scjdetail.vue'),
                name: 'scjdetail',
                meta: {title: '市场价', affix: true}
            },
            {
                path: '/xxjdetail',
                component: () => import('@/views/business/services/xxjdetail.vue'),
                name: 'xxjdetail',
                meta: {title: '信息价', affix: true}
            },
            {
                path: '/train',
                component: () => import('@/views/business/train/index.vue'),
                name: 'train',
                meta: {title: '培训服务', affix: true}
            },
            {
                path: '/trainDetail',
                component: () => import('@/views/business/train/detail.vue'),
                name: 'trainDetail',
                meta: {title: '技能培训', affix: true, requireAuth: true}
            },
            {
                path: '/userInfo',
                component: () => import('@/views/business/userInfo/index.vue'),
                name: 'userInfo',
                meta: {title: '用户中心', affix: true}
            },
            {
                path: '/releasedetail',
                component: () => import('@/views/business/services/releasedetail.vue'),
                name: 'releasedetail',
                meta: {title: '发布询价', affix: true}
            },
            {
                path: '/view',
                component: () => import('@/views/business/services/view.vue'),
                name: 'view',
                meta: {title: '查看询价', affix: true}
            },
            {
                path: '/viewdetail',
                component: () => import('@/views/business/services/viewdetail.vue'),
                name: 'viewdetail',
                meta: {title: '查看询价详情', affix: true}
            },
            {
                path: '/applicationdetail',
                component: () => import('@/views/business/train/applicationdetail.vue'),
                name: 'applicationdetail',
                meta: {title: '技能培训', affix: true}
            },
            {
                path: '/submitOrder',
                component: () => import('@/views/business/train/submitOrder.vue'),
                name: 'submitOrder',
                meta: {title: '提交订单', affix: true}
            },
        ]
    },
    {
        path: '*',
        component: () => import('@/views/business/home'),
        redirect: '/dashboard',
    }
]

const createRouter = () => new Router({
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router

router.beforeEach((to, from, next) => {
    let nextArr = ['/login', '/', '/proclamation', '/product', '/about', '/train', '/services','/proclamationDetial','/register','/forgetPassword']
    const tokenStr = window.sessionStorage.getItem('token')
    if (nextArr.indexOf(to.fullPath) != -1) {
        return next()
    }
    if (!tokenStr) {
      Message.warning("请先登录账号!")
      router.push({ path: "/login" }).catch(()=>{});
        // return next('/login')
    }else {
      next()
    }
})

