<template>
  <div :id="'myImage' + index" class="card-img">
    <el-upload
      class="theUpload"
      :class=" fileList.length >= limit || onlyShow == true ? 'notMyUpload' : 'myUpload'"
      action=""
      list-type="picture-card"
      :file-list="fileList"
      ref="imgupload"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }" class="my-img">
        <div
          class="el-upload-list__item-thumbnail"
          style="display: flex; justify-content: center"
        >
          <iframe
            :src="'pdf/web/viewer.html?file=' + file.url"
            frameborder="0"
            id="iframe"
            style="width: 100%; height: 550px"
          ></iframe>
        </div>
        <div class="img-new-box">
          <span
            class="el-upload-list__item-preview"
            style="    display: inline-block; padding-top: 23px;"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" style="font-size: 30px"></i>
          </span>
          <span>
             
          </span>
         
        </div>
      </div>
    </el-upload>
     <!-- v-dialogDrag -->
    <el-dialog
      class="my-imgcard-dialog"
      :visible.sync="dialogVisible"
      width="1200px"
     
      :modal="false"
      :append-to-body="true"
      :fullscreen="isFull"
      :custom-class="isFull ? 'preview-dialog' : ''"
    >
      <div style="width: 100%; height: 600px">
        <iframe
          :src="'pdf/web/viewer.html?file=' + dialogSrc"
          frameborder="0"
          style="width: 100%; height: 100%"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
// import { getNewOcrValue } from "@/api/ocrDiscern";
// import Viewer from "viewerjs";
// import Pdf from "vue-pdf";
// import "viewerjs/dist/viewer.css";
export default {
  name: "UploadImgCards",
  props: {
    dataOcrArray: {
      type: Array,
      default: [],
    },
    selectRadios: {
      type: String,
      default: '',
    },
    //上传文件最大数
    limit: {
      type: Number,
      default: 5,
    },
    //文件列表
    imageList: {
      type: Array,
      default: [],
    },
    //是否只读
    onlyShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    index: {
      type: String,
      default: "1",
    },
    ocrData: {
      type: Object,
      default: {},
    },
  },
  components: {
    // Pdf
  },
  data() {
    return {
      fileList: [],
      numPages: undefined,
      src: "",
      dialogSrc: undefined,
      isFull: false,
      fileType: "",
      dialogVisible: false,
      pdfLoading: false,
      allimgsrc: "",
    };
  },
  created() {
    // window.addEventListener("message", this.handleMessage);
    // 跟 ifrom 建立链接 改为这种， 只会出现一次 
    window.onmessage=(event) => { this.handleMessage(event) }
    this.$nextTick(() =>{
       // 第一次进入 删除之前存的 标注 编辑中的 不执行
       this.handleClickFatherToSon('delete')
    })
    
    this.fileList = this.imageList;
    // if (this.fileList.length > 0 && this.fileList[0].name != null) {
    //   let index = this.fileList[0].name.lastIndexOf(".");
    //   this.fileType = this.fileList[0].name.substring(
    //     index + 1,
    //     this.fileList[0].name.length
    //   );
    //   if (this.fileType == "pdf") {
    //     this.src = Pdf.createLoadingTask(this.fileList[0].url);
    //     this.src.promise.then((pdf) => {
    //       this.numPages = pdf.numPages;
    //       this.pdfLoading = true;
    //     });
    //   }
    // }
  },
  watch: {
    imageList(val) {
      this.fileList = val;
      this.fileType = "";
      this.src = "";
      if (val.length > 0 && val[0].name != null) {
        let index = val[0].name.lastIndexOf(".");
        this.fileType = val[0].name.substring(index + 1, val[0].name.length);
        if (this.fileType == "pdf") {
          this.src = Pdf.createLoadingTask(val[0].url);
          this.src.promise.then((pdf) => {
            this.pdfLoading = true;
            this.numPages = pdf.numPages;
          });
        }
      }
    },
    selectRadios(val){
       console.log('参数变化',val);
       this.handleClickFatherToSon('select')
    }
  },
  methods: {
    handleMessage(event) {
      const data = event.data.data;
      if (data) {
        if (data.code == "success") {
          console.log(data.test); 
          const { type ,text , name , ocrImg , jsonData} = data.test
          switch (type) {
            case 'tip': // ifrom 子页面传递过来的 提示
                this.$message.error(text);
              break;

            case 'img': // ifrom 子页面传递过来的 截图数据
                // 请求OCR
                this.handleOcrValue(name,ocrImg)
              break;

            case 'deleteOne': // pdf 中 删除了 某个标记
                // this.$message.error(text);
                this.handleStorageData(name,'',true)
              break;

            case 'json': // pdf页面 返回的 标注json 数据
                this.$emit('getPdfjson', JSON.stringify(jsonData) )
              break;
          
            default:
              break;
          }
        }
      }
    },
    // 请求 OCR 数据
    handleOcrValue(name,imgBlob){
      // let ocrValue = text.name + '我是ocr识别数据'
      let file = new File([imgBlob], "test.jpg", { type: imgBlob.type })
      console.log("File对象", file);
      let ocrFileData = new FormData(); // new formData对象
          ocrFileData.append("files", file); 
          ocrFileData.append("lxdm", this.ocrData.qyzxzjzy); 
      this.handleGetNewOcrValue(ocrFileData,name)
    },
    // ocr
    handleGetNewOcrValue(ocrFileData,name){
      getNewOcrValue(ocrFileData).then((res) => {
            if(res.code == 0){
                const { data  } = res
                this.handleStorageData(name,data[0].totalText)
            }
          });
    },
    //临时存储数据
    handleStorageData(name,ocrValue,isClear){
        var key = ''
        this.dataOcrArray.forEach((val) =>{
            if(val.text == name ){
                key = val.key
                val.ocrValue  = ocrValue
                val.writeValue = ocrValue
            }
        })
        this.$emit('EchoForm',this.dataOcrArray,key,isClear)
    },
    //给 ifrom 传递 所选的 字段
    handleClickFatherToSon(type,jsonPage) {
      if(type == 'sign'){
          if(!this.selectRadios){
            this.$message.error("请在左侧点击选择所要标记的字段!");
            return
          }
      }
      if(type == 'delete' || type == 'json' || type == 'saveJson'){
         setTimeout(() => {
          const iframe = document.getElementById("iframe")
          iframe.contentWindow.postMessage(
          {
              data: {
                code: "success",
                jsonPage:jsonPage,
                test: this.selectRadios,
                type:type // 类型 // sign标记 selece // 选择  delete //删除上次存留的
              },
          },
          "*"
          );
       }, 2000);
      } else {
          const iframe = document.getElementById("iframe")
          iframe.contentWindow.postMessage(
          {
              data: {
                code: "success",
                test: this.selectRadios,
                type:type // 类型 // sign标记 selece // 选择  delete //删除上次存留的 deleteOne //删除pdf中选中的 json // 获取标注的 JSON 数据
              },
          },
          "*"
          );
      }
    },
    //删除 pdf 中的 某一个标记
    handleDelete(){
       this.$confirm('确认删除此标记?, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.handleClickFatherToSon('deleteOne')
        })
    },
    // 修正数据
    handleEdit(){
       if(!this.selectRadios){
         this.$message.error("请先点击选择所要修正的字段!");
         return
       }
       this.$emit('editField',this.selectRadios)
    },
    // 获取标注json数据
    handleGetJsonData(){
       this.handleClickFatherToSon('json')
    },
    // 回显标注json数据
    handleSignJsonData(jsonPage){
      console.log('jsonPagejsonPage',);

       this.handleClickFatherToSon('saveJson',JSON.parse(jsonPage))
    },
    /**
     * 描述：图片预览
     * @param file 文件对象
     */
    handlePictureCardPreview(file) {
        this.dialogVisible = true;
        this.dialogSrc = file.url;
    },

    /**
     * 描述：图片移除
     * @param file 文件对象
     */
    handleRemove(file, fileList) {
      this.$alert("确定删除附件", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            this.doRemove(file);
          }
        },
      });
    },

    /**
     * 描述：图片移除接口
     * @param file 图片文件
     * @returns 移除的图片文件，所剩文件列表
     */
    doRemove(file) {
      this.fileList = this.fileList.filter((item) => {
        return item.uid !== file.uid;
      });
      this.$nextTick(() =>{
       //删除之前存的 标注 
       this.handleClickFatherToSon('delete')
       this.$emit('EchoForm',this.dataOcrArray,'')
    })
      this.$emit("doRemove", file, []);
    },
  },
  destroyed(){
    let clearStr = 'https://oocr.lnwlzb.com/ocr/api/ocr/getFile' 
    const keyArray = Object.keys(localStorage);
    keyArray.filter((key) => key.includes(clearStr)).forEach((key) => localStorage.removeItem(key));
  }
};
</script>
<style lang="scss" scoped>
.card-img {
  width: 100%;
  height: 100%;
  margin: 0 15px 0 0;
  position: relative;
  .noHave {
    height: 148px;
    width: 148px;
    border-radius: 10px;
    line-height: 148px;
    text-align: center;
    border: 1px solid rgb(216, 216, 216);
    color: rgb(216, 216, 216);
    font-size: 22px;
  }
}
.img-new-box{
  position: absolute;
  top: 41%;
  right: 0px;
  width: 6%;
  border-radius: 5px 0px 0px 5px;
  height: 14%;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: -5;
}
.img-new-box-two{
position: absolute;left: 0%;
bottom: 14px; width: 100%;
height: 9%;
color: rgb(255, 255, 255);
background: rgba(0, 0, 0, 0.5);
}
</style>
<style scoped>
.my-img {
  width: 100%;
  height: 100%;
  text-align: center;
}
.notMyUpload {
  width: 100%;
  height: 100%;
}
.notMyUpload >>> .el-upload--picture-card {
  display: none;
  height: 0px;
}
.theUpload >>> .el-upload--picture-card {
  width: 100%;
  /* height: 550px; */
  height: 100%;
  background-color: #fafafa;
}
.theUpload >>> .el-upload-list--picture-card .el-upload-list__item {
  /* height: 550px; */
  height: 100%;
  width: 100%;
}
.theUpload >>> .el-upload--picture-card i {
  display: none;
}
.myUpload {
  width: 100%;
  height: 100%;
}
.myUpload >>> .el-upload-list--picture-card .el-upload-list__item {
  margin-right: 15px;
}
.size-text {
  height: 30px;
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  top: 15px;
  color: gray;
}
.el-upload--picture-card {
  display: none;
}
.el-upload-list__item-thumbnail {
  /* height: 100%;
  width: auto; */
  height: auto;
  width: 100%;
}
.my-img >>> canvas {
  height: 100% !important;
  width: auto !important;
}
.btn-upload {
  text-align: center;
  margin-top: 30px;
}
.gpy-list {
  width: 321px;
  height: 200px;
  margin: 36px auto;
}
/* .my-img >>> .el-upload-list__item-actions{
    height: 10%;
    width:100%;
 } */
/* .el-upload-list__item-delete{
    display: block;
} */
</style>
