import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/geren/login',
    method: 'post',
    data: data
  })
}

export function getLoginIdentity() {
  return request({
    url: '/api/geren/getLoginIdentity',
    method: 'post',
  })
}

export function register(data) {
    return request({
      url: '/api/geren/register',
      method: 'post',
      data: data
    })
  }


export function registerByCompany(data) {
  return request({
    url: '/api/geren/registerByCompany',
    method: 'post',
    data: data
  })
}


export function getAllMesCount(data) {
  return request({
    url: '/api/geren/getAllMesCount/' + data,
    method: 'post',
    data: data
  })
}

export function forgetPassword(data) {
  return request({
    url: '/api/geren/forgetPassword',
    method: 'post',
    data: data
  })
}

  export function createShoppingCard(data) {
    return request({
      url: 'api/geren/createShoppingCard',
      method: 'post',
      params: data,
    })
  }
  /**
 * 创建支付订单
 */
export function handleCreatePay(params) {
  return request({
    url: `/api/geren/unifiedOrder`,
    method: 'post',
    data: params,
  })
}

export function selectOrderIsPaySuccess(params) {
    return request({
        url: `/api/geren/getPayStatus`,
        method: 'post',
        data: params,
    })
}

export function createOrderInfo(data) {
  return request({
    url: 'api/geren/createOrderInfo',
    method: 'post',
    params: data,
  })
}

export function getOrderDetails(data) {
  return request({
    url: 'api/geren/getOrderDetails',
    method: 'post',
    data: data,
  })
}

export function deleteOrder(data) {
  return request({
    url: 'api/geren/deleteOrder',
    method: 'post',
    data: data,
  })
}


/**
 * pdf 预览
 * @param methodName
 * @param data
 * @returns {AxiosPromise}
 */
export function seeContract(data) {
  return request({
    url: '/api/geren/seeContract',
    method: 'post',
    data:data,
    responseType: 'blob'
  })
}


export function seeInvoice(data) {
  return request({
    url: '/api/geren/seeInvoice',
    method: 'post',
    data:data,
    responseType: 'blob'
  })
}

export function downLoadContract(data) {
  return request({
    url: '/api/geren/downLoadContract',
    method: 'post',
    responseType: 'blob',
    data: data
  })
}

export function downLoadInvoice(data) {
  return request({
    url: '/api/geren/downLoadInvoice',
    method: 'post',
    responseType: 'blob',
    data: data
  })
}

export function uploadContract(id,data) {
  return request({
    url: `/api/geren/uploadContract/${id}`,
    method: 'post',
    data: data
  })
}

export function getOrderList(data) {
  return request({
    url: `api/geren/getOrderList?pageNum=${data.pageNum}&pageSize=${data.pageSize}&orderByColumn=${data.orderByColumn}&isAsc=desc`,
    method: 'post',
    data: data,
  })
}

export function getOrderContractList(data) {
  return request({
    url: `api/geren/getOrderContractList?pageNum=${data.pageNum}&pageSize=${data.pageSize}&orderByColumn=${data.orderByColumn}&isAsc=desc`,
    method: 'post',
    data: data,
  })
}

export function createOrder(data) {
  return request({
    url: 'api/geren/createOrder',
    method: 'post',
    data: data,
  })
}

export function updateOrderInfo(data) {
  return request({
    url: 'api/geren/updateOrderInfo',
    method: 'post',
    data: data,
  })
}


export function getUserInfo(data) {
  return request({
    url: 'api/geren/getUserInfo',
    method: 'post',
    data: data,
  })
}


export function updateInfo(data) {
  return request({
    url: 'api/geren/updateInfo',
    method: 'post',
    data: data,
  })
}


export function getOrderInvoiceInfoList(data) {
  return request({
    url: 'api/geren/getOrderInvoiceInfoList',
    method: 'post',
    data: data,
  })
}

export function getOrderInvoiceList(data) {
    return request({
        url: 'api/geren/getOrderInvoiceList',
        method: 'post',
        data: data,
    })
}

export function saveOrUpdateOrderInvoiceInfo(data) {
  return request({
    url: 'api/geren/saveOrUpdateOrderInvoiceInfo',
    method: 'post',
    data: data,
  })
}


export function buyOneTime(data) {
  return request({
    url: 'api/geren/buyOneTime',
    method: 'post',
    data: data,
  })
}

export function logout(data) {
  return request({
    url: 'api/geren/logout',
    method: 'post',
    data: data,
  })
}



export function checkCanUsePhone(data) {
    return request({
        url: 'api/geren/checkCanUsePhone',
        method: 'post',
        data: data,
    })
}


export function createTask(data) {
  return request({
    url: `api/geren/createTask?orderId=${data.orderId}&personnelCode=${data.personnelCode}`,
    method: 'post',
    data: data,
  })
}

export function getCacheCode(data) {
  return request({
    url: `api/geren/captcha/digit?uuid=${data}`,
    method: 'get',
    responseType:'blob'
  })
}


export function cjXjLogin(data) {
  return request({
    url: `api/cjXj/login?userId=${data.userId}&to=${data.to}&provinces=${data.provinces}`,
    method: 'get'
  })
}

export function getSoldProdList(data) {
  return request({
    url: `/api/geren/getIsSaleProd?pageNum=${data.pageNum}&pageSize=${data.pageSize}&isAsc=desc`,
    method: 'post',
    data: data,
  })
}
