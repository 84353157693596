<script>
export default {
  name: "Descriptions",
  props: {
    detailList: {
      type: Array,
      default: () => [], // 默认返回空对象
    },
  },
  methods: {
    renderTable() {
      const { $attrs: attrs, detailList } = this;
      return (
        <el-descriptions attrs={attrs}>
          {detailList.length != 0 &&  detailList.map((col) => {
              return (
                <el-descriptions-item labelClassName={ 'my-descriptions'} label={col.label}>
                  {col.value}
                </el-descriptions-item>
              );
            })}
            <template slot="extra">
              <el-button style="width:130px;" type="primary" plain  onClick={e => this.handleBack()} >返回</el-button>
            </template>
        </el-descriptions>
      );
    },
    handleBack(){
      this.$router.push({
        path:'/formulaHome'
      })
    }
  },
  render() {
    return <div id="descriptions">{this.renderTable()}</div>;
  },
};
</script>

<style scoped>
#descriptions {
  margin-top: 20px;
}
#descriptions >>> .el-descriptions__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  font-size: 20px;
  padding: 17px 30px 20px;
  margin: auto;
  border-bottom: 1px solid #9e9e9e40;
}
#descriptions >>> .el-descriptions__title {
  font-size: 20px;
}
#descriptions >>> .el-descriptions__body {
  color: #606266;
  background-color: #fff;
  padding: 20px 100px;
}
#descriptions >>> .el-descriptions__body .el-descriptions__table {
  font-size: 16px;
}
#descriptions >>> .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 35px;
}
#descriptions >>> .my-descriptions{
    width: 145px;
    display: inline-block;
    text-align: end;
}
</style>