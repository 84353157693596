const Token = 'token'
const TokenByZf = 'tokenByZf'
const UserId = 'UserId'
const RoleInfo = 'RoleInfo'

export function getToken() {
  return localStorage.getItem(Token)
}

export function getTokenByZf() {
  return sessionStorage.getItem(TokenByZf)
}

export function setToken(token) {
  return localStorage.setItem(Token, token)
}

export function setTokenByZf(tokenByZf) {
  return sessionStorage.setItem(TokenByZf, tokenByZf)
}

export function removeToken() {
  return localStorage.removeItem(Token)
}

export function removeZfToken() {
  return sessionStorage.removeItem(TokenByZf)
}

export function getUserId() {
  return localStorage.getItem(UserId)
}

export function setUserId(userId) {
  return localStorage.setItem(UserId, userId)
}

export function removeUserId() {
  return localStorage.removeItem(UserId)
}

export function getRoleInfo() {
  return localStorage.getItem(RoleInfo)
}

export function setRoleInfo(roleInfo) {
  return localStorage.setItem(RoleInfo, roleInfo)
}

export function removeRoleInfo() {
  return localStorage.removeItem(RoleInfo)
}
