/* eslint-disable */
import axios from 'axios'
import { Message } from 'element-ui'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
    timeout: 50000
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Authorization'] =  window.sessionStorage.getItem('token')
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        if( (toString.call(response.data) !== '[object Blob]') && response.data.code !== 200){
            if(response.data.code == 401){
                window.sessionStorage.removeItem("token");
                window.sessionStorage.removeItem("loginId");
                // this.$router.replace('/login');
                Message({
                    message: response.data.message || response.data.msg || '服务器异常！',
                    type: 'error'
                })
                // router.app.$router.replace("/login")
                return
            }
                Message({
                    message: response.data.message || response.data.msg || '服务器异常！',
                    type: 'error'
                })
            return {"code":500,"msg":response.data.msg,data:{}}
        }
        let res = response.data;
        // if (res.code !== 0 && res.code !== 200) {
        //     console.log('code非0并非200：', res)
        //     Message({
        //         message: res.message || res.msg || '服务器异常！',
        //         type: 'error'
        //     })
        //     return Promise.reject(new Error('Error'))
        // } else {
            return res
        // }
    },
    error => {
         Message({
            message: '数据请求失败！',
            type: 'error'
        })
        return Promise.reject(error)
    }
)

export default service
