import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import compInstall from '@/plugins/installComponents'
import '@/plugins/publicMethods'
import './plugins/element.js'
import Element from 'element-ui'
import TDesign from 'tdesign-vue';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '12yiGNPdYCMhV8LGhDEerCnL2kaVdG2i'
});
// 全局组件挂载
Vue.use(compInstall)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.use(Element, {
  // size: Cookies.get('size') || 'medium'
})
Vue.use(TDesign);