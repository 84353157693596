<template>
  <div id="app">
    <router-view/>
    <BackToTop></BackToTop>
  </div>
</template>

<script>
import DevicePixelRatio from "@/api/system/devicePixelRatio";
export default {
  name: "App",
  created() {
    new DevicePixelRatio().init();
	}
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
}
</style>
