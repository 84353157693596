<template>
  <div id="layout">
    <el-container>
      <el-header style="height:65px;top: 0;left: 0;z-index: 1500;position: fixed;padding: 0;">
        <div class="content">
          <div class="content-left">
            <img class="my-img" src="../assets/images/top_logo.png" alt="">
          </div>
          <div class="buttom">
            <ul class="list-head">
              <li :class="['list1',routeName == 'dashboard' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/')">
                <router-link to="/" class="firstA">首页</router-link>
              </li>
              <li :class="['list1',routeName == 'proclamation' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/proclamation')">
                <a>资讯中心</a>
              </li>
              <li :class="['list1',routeName == 'product' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/product')">
                <a>产品中心</a>
              </li>
              <li :class="['list1',routeName == 'train' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/train')">
                <a>培训服务</a>
              </li>
              <li :class="['list1',routeName == 'services' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/services')">
                <a>材价服务</a>
              </li>
              <li :class="['list1',routeName == 'about' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/about')">
                <a>关于我们</a>
              </li>
              <li :class="['list1',routeName == 'userInfo' ? 'select' : '',toTheOldPlatform ? 'list1-mar2' : 'list1-mar1']" @click.stop="handleJumpRoute('/userInfo')">
                <a>用户中心</a>
              </li>
            </ul>
          </div>
          <div class="content-right">
            <el-button size="small" type="warning" v-if="islogin" round @click="handleclick()" style="background-color: rgb(244,141,17); ">登录/注册</el-button>
            <el-button size="small" type="warning" v-if="isOut" round disabled style="background-color: rgb(244,141,17); ">{{ loginId }}， 欢迎你</el-button>
            <el-button size="small" type="warning" v-if="isOut" round @click="handlecancel()" style="background-color: rgb(244,141,17); ">退出</el-button>
          </div>
        </div>
      </el-header>
      <el-main style="padding: 0;">
        <div style="width: 100%;">
          <app-main />
          <!-- <router-view /> -->
        </div>
      </el-main>
      <el-footer style="height:100%;background-color: #181818;margin-top: 2%;">
        <div class="foot-head">
          <div class="foot-head-num">本站总访问量：{{ footInfo.num }}</div>
          <div class="foot-head-ex">主办单位：{{ footInfo.zbdw }}</div>
          <div class="foot-head-ex">单位地址：{{ footInfo.dwdz }}</div>
        </div>
<!--        <div class="foot-foot">-->
<!--          辽宁公安备{{ footInfo.bah }}号 | 备案/许可证编号为：{{ footInfo.xkzh }} | 版权所有：{{ footInfo.bqsy }}-->
<!--        </div>-->
        <div class="foot-foot">
         备案/许可证编号为：{{ footInfo.xkzh }} | 版权所有：{{ footInfo.bqsy }}
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
  import {logout} from "@/api/system/login";
import appMain from "@/components/AppMain.vue"
export default {
  data() {
    return {
      isOut :true,
      islogin :false,
      loginId :sessionStorage.getItem('loginId'),
      cachedViews : ['FormulaHomeList'],
      tabPosition:'cjrj',
      imgList:[
        {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',},
        {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',},
        {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',},
      ],
      xwList:[
      {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',data:'关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列'},
      {imgersssd:'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',data:'关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列'},
      {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',data:'关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列'},
      {imgersssd:'https://fuss10.elemecdn.com/a/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',data:'关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列'},
      {imgersssd:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',data:'关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列关于展开工程系列'},
      ],
      cpList:[
        {name:'计价软件'},
        {name:'材价软件'},
        {name:'清标软件'},
      ],
      toThe:1,
      stuThe:0,
      pxType:[
        {code:1,name:'在线直播'},
        {code:2,name:'技能考试'},
        {code:3,name:'现场培训'},
        {code:4,name:'在线录播'},
        {code:5,name:'造价工程'},
      ],
      stuList:[
        {code:1,img:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',name:'课程名称一课程名称一',content:'内容内容内容内容内容',price:100},
        {code:2,img:'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',name:'课程名称二课程名称二课程名称二课程名称二课程名称二课程名称二课程名称二课程名称二课程名称二',content:'内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容',price:0},
        {code:3,img:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',name:'课程名称三课程名称三课程名称三课程名称三课程名称三课程名称三课程名称三课程名称三课程名称三',content:'内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容',price:0},
      ],
      footInfo:{
        num:2432442,
        zbdw:'辽宁省精研数字造价技术有限公司',
        dwdz:'辽宁省沈阳市皇姑区北陵大街34-4号402室',
        bah:'210000000000000000000',
        xkzh:'辽ICP备2023006960号',
        bqsy:'辽宁省精研数字造价技术有限公司'
      }
    }
  },
  components:{
    appMain,
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
      if (sessionStorage.getItem('token') == undefined) {
        this.isOut = false
        this.islogin = true
      }
    },
  methods: {
    handleclick(){
      this.$router.replace('/login');
   },
    handlecancel(){
      this.$confirm('确认退出登陆？').then(_ => {
        logout({id: sessionStorage.getItem("userId")}).then(res =>{
          this.$router.replace('/login');
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("loginId");
        })
      }).catch(_ => {});
    },
    haldeMous(val){
      this.toThe = val
    },
    haldeMousStudy(val){
      this.stuThe = val
    },
    handleJumpRoute(route) {
      // if(route == '/train'){
      //   this.$alert("暂未开放!", "", { confirmButtonText: "确定" }).catch(()=>{});
      //   return
      // }
      this.$router.push({
        path: route,
      }).catch(()=>{});
    },
  }
}
</script>
<style scoped lang="scss">
a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
ol,
li {
  list-style: none;
}
#layout {
  width: 100%;
  height: 100%;
}
.select {
    // border-bottom: 4px solid #3266b6;
    border-bottom: 4px solid #F48D11;
  }
.content {
    width: 100%;
    height: 100%;
    padding-left: 240px;
    background: url(../assets/images/headBg.jpg);
    box-shadow: 0px 4px 5px 0px rgba(33,22,19,0.3);
    // background-color: #211613;
    .content-left,.content-right{
     float: left;
     width: 20%;
     height: 100%;
     display: flex;
     align-items: center;
     .my-img{
        width: 160px;
        // height: 70px;
        align-items: center;
     }
    }
    .content-right{
     float: right;
     width: 15%;
     height: 100%;
     margin-right: 110px;
     justify-content: end;
    }
    .buttom{
      width: 935px;
      height: 100%;
      float: left;
      .list-head {
        margin: 0;
      }
      .list-head li.list1{
        width: 115px;
        height: 100%;
        float: left;
        text-align: center;
        font-size: 16px;
        padding-bottom: 17px;
        position: relative;
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        padding-top: 21px;
        list-style: none;
        cursor:pointer;
        text-decoration: none;
      }
      .list1-mar1 {
        margin-right: 15px;
      }
      .list1-mar2 {
        margin-right: 5px;
      }
      .list1:hover {
        background-color: #F48D11;
        // background-color: #3266b6;
        color: #fff;
      }
      .list-head li.listWidth {
        width: 100px;
        margin-right: 212px;
      }
      .list-head li.list1.on {
        // background: #3266b6;
        background: #F48D11;
      }
      .list-head li.activeBg {
        // background: #3266b6;
        background: #F48D11;
      }
      .list-head li.lastWidth {
        margin-right: 0;
      }
    }
  }
.el-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-image: url("../assets/images/banner-bg.jpg");
  background-size: 100%;
  width: 100%;
}
.foot-head{
  width: 100%;
  height: 135px;
  .foot-head-num{
    width: 300px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 15%;
    text-align:left; 
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .foot-head-ex{
    width: 680px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-left: 15%;
    text-align:left; 
  }
}
.foot-foot{
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
</style>