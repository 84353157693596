/* eslint-disable */ 
const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  zfToken: state => state.user.zfToken,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,

  examinationWayOptions: state => state.codelist.examinationWayOptions,
  courseAddress: state => state.codelist.courseAddress,
  teachingAddressOptions: state => state.codelist.teachingAddressOptions,
  courseLengthOptions: state => state.codelist.courseLengthOptions,
  hostUnitOptions: state => state.codelist.hostUnitOptions,
  teachingRegionOptions: state => state.codelist.teachingRegionOptions,
  MyCompanyName: state => state.codelist.MyCompanyName,
  // 平台
  platFormList: state => state.user.platFormList,
  //辽易通
  platFormListLyt: state => state.user.platFormListLyt,
  // 服务商
  serviceProviders: state => state.user.serviceProviders,
  // 介质
  mediumList: state => state.user.mediumList,
  // 厂商
  manufacturer: state => state.user.manufacturer,
  // 证书类型
  certificateType: state => state.user.certificateType,
  // ca锁类型
  caDogType: state => state.user.caDogType,

  ROLEOPTIONS: state => state.codelist.ROLEOPTIONS,

  roleList: state => state.user.roleList,

  getterInfo: state => state.user.getterInfo,

  roleOptionList:state => state.user.roleOptionList,
  //阉割版本状态码
  castrationFlag:state => state.user.castrationFlag,

  toTheOldPlatform:state => state.user.toTheOldPlatform,

  showLytContent:state => state.user.showLytContent,

  //再存新办对象
  addCerEnterpriseStaging: state => state.codelist.addCerEnterpriseStaging,

  //是否有缓存
  addStagingFlag: state => state.codelist.addStagingFlag,
  
}
export default getters
