import Vue from 'vue'
import {
    Collapse, CollapseItem, Image, Card, Option, Select, DatePicker, Row, Col, Upload, Container, Header, Footer, Main, Pagination,
    Checkbox, Cascader, Tooltip, RadioButton, RadioGroup, Form, FormItem, MessageBox, CheckboxButton, CheckboxGroup, Button,
    Input, Dialog, Message, Loading, Descriptions, DescriptionsItem, tag, Table, TableColumn , Carousel , CarouselItem
} from 'element-ui'; // 按需引入组件
// 注册全局组件

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(Cascader)
Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(Descriptions)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Upload)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Option)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Pagination)
Vue.use(DescriptionsItem)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(tag)
Vue.use(Loading)
Vue.use(Loading.directive);  //  指令方式(v-loading)的话这两行都得有
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.config.productionTip = false